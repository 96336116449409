import {useEffect, useState} from 'react';

const GTM_KEY = process.env.REACT_APP_WITH_GTM_ID;

const UseTrackingGTM = ({event}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (GTM_KEY && !loaded) {
      // Dynamically create a script element
      const script = document.createElement('script');

      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_KEY}`;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        setLoaded(true);
      };

      // Clean up the script on component unmount
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [loaded]);

  useEffect(() => {
    if (event && loaded) {
      window.dataLayer.push(event);
    }
  }, [event, loaded]);

  return null;
};

export default UseTrackingGTM;
